































































































































































































































.btn-change {
  display: block;
  width: 100%;
  height: 60px;
  margin-top: 32px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 18px;
  background-color: #222;
  color: #fff;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
}
.change-password {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  &__dim {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
  &__article {
    position: relative;
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
  }
  &__section {
    display: table;
    width: 100%;
  }
  &__row {
    display: table-row;
  }
  &__cell {
    display: table-cell;
    padding: 10px 0;
    vertical-align: middle;
    & .tf_comm {
      margin-top: 0;
    }
  }
  &__title {
    display: block;
  }
}
.effect {
  $dimDuration: 0.2s;
  $articleDuration: 0.3s;
  @mixin fakeEffect {
    opacity: 1;
    transition: opacity 0.5s linear 0s;
  }
  &__password-enter-active,
  &__password-leave-active {
    @include fakeEffect;
  }
  &__password {
    &-enter {
      &-active {
        .change-password {
          &__dim {
            opacity: 0;
            transition: opacity $dimDuration ease-in 0s;
          }
          &__article {
            opacity: 0;
            transform: translateY(30px);
            transition: opacity $articleDuration ease-in 0.1s,
              transform $articleDuration ease-in 0.1s;
          }
        }
      }

      &-to {
        .change-password {
          &__dim {
            opacity: 1;
          }
          &__article {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }
    }
    &-leave {
      &-active {
        .change-password {
          &__dim {
            opacity: 1;
            transition: opacity $dimDuration ease-out 0.2s;
          }
          &__article {
            opacity: 1;
            transform: translateY(0px);
            transition: opacity $articleDuration ease-out 0s, transform $articleDuration ease-out 0s;
          }
        }
      }
      &-to {
        .change-password {
          &__dim {
            opacity: 0;
          }
          &__article {
            opacity: 0;
            transform: translateY(-50px);
          }
        }
      }
    }
  }
}
